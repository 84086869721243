<template>    
    <div>
        <div>
            <membroNovo />
        </div>     
    </div>
</template>
<script>
  import membroNovo from '../../components/membroPageNovo.vue'
  export default {

    components: {
        membroNovo
    },
    methods: {

    }
}
</script>

<style scoped>


</style>