
<template>
  <div>

    <article class="panel is-outlined">
      <p class="panel-heading">
        Cadastro de Congregações - Módulo Alteração
      </p>

      <span style="position:absolute; top: 40%; left: 35%;
      z-index:1000; background-color:#333; color:#fff; width: 20%;
      height:auto;text-align:center; border-radius:10px; "> {{msg}} </span>

      <div class="principal">

        <div>
          <label>Razão Social:</label><br>
          <input v-model="razaoSocial" class="input" type="text" id="razaoSocial" style="width: 400px;" />&nbsp &nbsp
        </div>

        <div>
          <label>E-mail:</label><br>
          <input v-model="email" class="input" type="email" id="email" style="width: 400px;" />
        </div>

      </div>

      <div class="principal">
        <div>
          <label>Endereço:</label><br>
          <input v-model="endereco" class="input" type="text" id="endereco" style="width: 400px;" />&nbsp &nbsp
        </div>
        <div>
          <label>UF:</label><br>
            <!--<select v-model="estado">
            <option disabled value="">UF</option>
            <option>RJ</option>
            <option>MG</option>
            <option>SP</option>
          </select>&nbsp &nbsp -->
          <select v-model="estado" class="select">
              <option v-for="option in options" v-bind:value="option.value">
                  {{ option.text }}
              </option>
          </select>&nbsp &nbsp
          <!-- <input v-model="estado" class="input" type="text" id="estado" style="width: 50px;" />&nbsp &nbsp -->
        </div>
        <div>
          <label>CEP:</label><br>
          <input v-model="cep" class="input" type="text" id="cep" style="width: 120px;" />&nbsp &nbsp
        </div>

        <div>
          <label>Bairro:</label><br>
          <input v-model="bairro" class="input" type="text" id="bairro" style="width: 300px;" /><br>
        </div>
      </div>

      <div class="principal">
        <div>
          <label>Dirigente:</label><br>
          <input v-model="dirigente" class="input" type="text" id="dirigente" style="width: 400px;" />&nbsp &nbsp
        </div>

        <div>
          <label>Telefone:</label><br>
          <input v-model="telefone" class="input" type="text" id="telefone" style="width: 300px;" />&nbsp &nbsp
        </div>
      </div>

      <div class="btn">
        <br>
        <button class="button is-info is-outlined" @click="gravar()">A l t e r a r </button><br><br>
      </div>


    </article>

<!--     <div style="margin-bottom: 100px; border: 1px solid rgb(8, 0, 0); margin-left: 5px">
      <span> {{ msg }} </span>
    </div> -->

  </div>
</template>
  
<script>
import axios from "axios";
export default {
  data() {
    return {
      name: 'pageEditCongregacao',
      id: "",
      msg: "",
      razaoSocial: "",
      email: "",
      endereco: "",
      cep: "",
      bairro: "",
      telefone: "",
      estado: 'RJ',
        options: [
          { text: 'Acre', value: 'AC' },
                    { text: 'Alagoas', value: 'AL' },
                    { text: 'Amapa', value: 'AP' },
                    { text: 'AM', value: 'AM' },
                    { text: 'Bahia', value: 'BA' },
                    { text: 'Ceará', value: 'CE' },
                    { text: 'Distrito Federal', value: 'DF' },
                    { text: 'Espirito Santo', value: 'ES' },
                    { text: 'Goiás', value: 'GO' },
                    { text: 'Maranhão', value: 'MA' },
                    { text: 'Mato Grosso', value: 'MT' },
                    { text: 'Mato Grosso do Sul', value: 'MS' },
                    { text: 'Minas Grrais', value: 'MG' },
                    { text: 'Pará', value: 'PA' },
                    { text: 'Paraíba ', value: 'PB' },
                    { text: 'Paraná', value: 'PR' },
                    { text: 'Pernambuco', value: 'PE' },
                    { text: 'Piauí', value: 'PI' },
                    { text: 'Rio de Janeiro', value: 'RJ' },
                    { text: 'Rio do Grande do Norte', value: 'RN' },
                    { text: 'Rio do Grande do Sul', value: 'RS' },
                    { text: 'Rôndonia', value: 'RO' },
                    { text: 'Roraima', value: 'RR' },
                    { text: 'Santa Catarina', value: 'SC' },
                    { text: 'São Paulo', value: 'SP' },
                    { text: 'Sergipe', value: 'SE' },
                    { text: 'Tocantins', value: 'TO' }
                 ],
      dirigente: "",
      //dados: null,
      url: process.env.VUE_APP_API_URL,
      port: process.env.VUE_APP_PORT,
    }
  },
  mounted() {
    this.getCongregacao()
  },
  methods: {
    async gravar(id) {
      console.log('Click de Alterar');


      var req = {
               headers: {
                 Authorization: "Bearer " + localStorage.getItem("tokenIgreja"),
               },
            }; 

      console.log('Botão de Altarar Congregação: '+req)
      axios.post(this.url + ":" + this.port + "/igreja/alterar", {
        id: this.id,
        razaoSocial: this.razaoSocial,
        email: this.email,
        endereco: this.endereco,
        cep: this.cep,
        bairro: this.bairro,
        telefone: this.telefone,
        estado: this.estado,
        dirigente: this.dirigente
      },req)
        .then((res) => {
          this.msg = "Congregação Alterada com sucesso";
          setTimeout(() => (this.msg = ""), 3000);


          this.$router.push('/congregacao');
          console.log(res);
        })
        .catch((err) => {
          this.msg = err.response.data.error;
          console.log(this.msg);
          setTimeout(() => (this.msg = ""), 3000);
        });


    },
    getCongregacao() {
      this.id = this.$route.params.id;
      console.log(this.id);

      axios.get(this.url + ":" + this.port + "/igrejaid/" + this.id,)   //req
        .then((res) => {
          const dados = res.data[0];
          this.razaoSocial = dados.razaoSocial;
          this.endereco = dados.endereco;
          this.email = dados.email;
          this.cep = dados.cep;
          this.bairro = dados.bairro;
          this.telefone = dados.telefone;
          this.estado = dados.estado;
          this.dirigente = dados.dirigente;

          console.log(this.razaoSocial + ' - ' + this.dirigente);
        }).catch((error) => {
          console.log(error);
        });
    }


  }

}
</script>
  
<style scoped>
.lbl {
  font-size: 10px;
  font-weight: bold;
  margin-left: 2%;
}

.principal {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
}

.principal label {
  font-size: 12px;
  font-weight: bold;
  margin-left: 2%;
}

.btn {
  margin-left: 2%;
}

</style>