import axios from "axios";

// axios.post(url+":"+port+"/usuario/new"
const host = process.env.VUE_APP_API_URL;
const port = process.env.VUE_APP_PORT;

export const fetcher = {
    get: async (url) => axios.get(`${host}:${port}${url}`),
    put: async (url, body) => axios.put(`${host}:${port}${url}`,{ ...body }),
    post: async (url, body) => axios.post(`${host}:${port}${url}`, { ...body }),
    delete: async (url) => axios.delete(`${host}:${port}${url}`) 
}
