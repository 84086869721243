<template>
  <div style="height: 78vh !important;">
    
  </div>
<!--   <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
</template>

<script>
export default {

}
</script>
