<template>
    <div>
        <h2>Registro de Usuários</h2>
        <hr>
        <div class="columns is-mobile is-centered">
            <div class="column is-half">
                <p>Nome</p>
                <input type="text" name="nome" id="nome" placeholder="Digite o nome do usuário" class="input"
                    v-model="nome">
                <p>E-Mail</p>
                <input type="text" name="email" id="email" placeholder="Digite o email do usuário" class="input"
                    v-model="email">
                <p>Senha</p>
                <input type="password" name="senha" id="senha" placeholder="******" class="input" v-model="senha">
                <hr>
                <button class="button is-success" @click="register()">Cadastrar</button>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            nome: '',
            senha: '',
            email: ''
        }
    },
    methods: {
        register() {
            console.log(this.nome);
            console.log(this.email);
            console.log(this.senha);
        }
    }

}
</script>

<style scoped></style>