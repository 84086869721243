<template>
    <div class="card" v-if="showForm">
        <p>Fichas Cadastrais</p>

        <div class="container">

            <div class="label">
                Matr.Inicial:
            </div>
            <div class="texto">
                <input type="text" v-model="idIni">
            </div>

            <div class="label">
                Matr.Fim:
            </div>
            <div class="texto">
                <input type="text" v-model="idFim">
            </div>
            <br />

        </div>
        <div class="menuButon">

            <input type="button" value="Ok" id="btnOk" @click="imprimir()">
            <input type="button" value="Fechar" id="btnFechar" @click="fechar">

        </div>
    </div>
</template>
<script>
import jsPDF from 'jspdf';
import axios from 'axios';
import fetcher from '@/lib/fetcher'
import { onMounted } from 'vue';
import { getUserInfo } from '@/lib/tools';

export default {

    components: {
        fetcher
    },
    props: ["logo", "alt"],
    data() {
        return {
            registros: [],
            idIni: 1,
            idFim: 22,
            idCongregacao: 999,
            showForm: true,
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,


            dados: {
                id: 1,
                nome: 'João da Silva',
                endereco: 'Rua A, 123',
                bairro: 'Centro',
                cep: '12345-678',
                nomePai: 'José da Silva',
                nomeMae: 'Maria da Silva',
                conjugue: 'Ana da Silva',
                profissao: 'Professor',
                celular: '(11) 98765-4321',
                cpf: '123.456.789-00',
                rg: '12.345.678-9',
                orgao: 'SSP',
                dtemissao: '01/01/2020',
                nascimento: '15/03/1980',
                naturalidade: 'São Paulo',
                estado: 'SP',
                qtd_filhos: 2,
                cargo: 'Líder',
                dtbatismoAguas: '10/10/2000',
                batismoES: 'Batismo ESP',
                recebidoPor: 'Pastor João',
                dataRecebido: '20/01/2024',
                igrejaAnterior: 'Igreja ABC',
                cargoAnterior: 'Membro',
                tempoCargo: '5 anos'
            }
        }
    },

    methods: {

        async starta(){
            //console.log('CongregacaoId........:'+this.idCongregacao);
            var userInfo = getUserInfo()
            this.idCongregacao = userInfo.congregacaoId;
            console.log('CongregacaoId........:'+this.idCongregacao);
            console.log(userInfo)
        },

        async imprimir() {

            var userInfo = getUserInfo()
            console.log(userInfo);
            this.congregacaoId = userInfo.congregacaoId;
            console.log('congregação ID .........:'+userInfo.congregacaoId);

            console.log('<<<<<<<<<<< userInfo   >>>>>>>>>>');
            console.log(userInfo);
            console.log(userInfo.nivel);
            if((userInfo.nivel == 1) || (userInfo.nivel == 3 )){
                const url = `${this.url}:${this.port}/fichaMembros`;
            //this.url + ":" + this.port + "/fichaMembros"
            await axios.get(url, {
                params: {
                    idIni: this.idIni,
                    idFim: this.idFim,
                    idCongregacao: this.idCongregacao
                }
            })
                .then((res) => {
                    /* let data = JSON.parse(JSON.stringify(res.data));
                    console.log(data);
                    data = data.map(item => ({
                        id: item.id,
                        cpf: item.nome,
                        endereco: item.endereco,
                        bairro: item.bairro,
                        cep: item.cep,
                        nomePai: item.nomePai,
                        nomeMae: item.nomeMae,
                        conjugue: item.conjugue,
                        profissao: item.profissao,
                        celular: item.celular,
                        cpf: item.cpf,
                        rg: item.rg,
                        orgao: item.orgao,
                        dtemissao: item.dtemissao,
                        nascimento: item.nascimento,
                        naturalidade: item.naturalidade,
                        estado: item.estado,
                        qtd_filhos: item.qtd_filhos,
                        cargo: item.cargo,
                        dtbatismoAguas: item.dtbatismoAguas,
                        batismoES: item.batismoES,
                        batismoES: item.batismoES,
                        recebidoPor: item.recebidoPor,
                        dataRecebido: item.dataRecebido,
                        igrejaAnterior: item.igrejaAnterior,
                        cargoAnterior: item.cargoAnterior,
                        tempoCargo: item.tempoCargo
                    }));
                    this.registros = data; */
                    console.log('id Inicio: '+this.idIni);
                    console.log('id Fim: '+this.idFim);
                    console.log('id Congregação: '+this.idCongregacao);
                    
                    const data = res.data;
                    this.registros = Array.isArray(data) ? data : [];

                })
                .catch((err) => {
                    this.registros = [];
                    console.log(err.response.data.error);
                });

                await this.gerarPdf();

            }
            
        },
        async gerarPdf() {

            const doc = new jsPDF({ unit: 'mm', format: 'a4' });
            const margin = 10;
            const headerText = 'Ficha de Membro';
            const footerText = 'Página: ';
            const boxHeight = 7; // Altura da caixa de texto
            const lineWidth = 0.5; // Largura da linha
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;

            for (let i = 0; i < this.registros.length; i++) {
                const registro = this.registros[i];

                if (i > 0) {
                    doc.addPage(); // Adiciona uma nova página para cada registro
                }
                let yPosition = margin;

                // Adiciona o cabeçalho centralizado
                doc.setFontSize(15);
                const headerWidth = doc.getTextWidth(headerText); // Calcula a largura do texto do cabeçalho
                const xPosition = (pageWidth - headerWidth) / 2; // Calcula a posição X para centralizar o texto
                doc.text(headerText, xPosition, yPosition);
                doc.text('Assembleia de Deus Central Rigoleto', xPosition - 20, yPosition + 10);
                yPosition += 10; // Espaço após o cabeçalho

                // Adiciona a linha abaixo do cabeçalho
                doc.setLineWidth(lineWidth);
                doc.line(margin, yPosition + 5, pageWidth - margin, yPosition + 5); // Linha horizontal abaixo do cabeçalho
                yPosition += 12; // Espaço após o cabeçalho

                // Adiciona as informações do registro
                doc.setFontSize(10);

                const lines = [
                    `ID: ${registro.id}`,
                    `Nome: ${registro.nome}`,
                    `Endereço: ${registro.endereco}`,
                    `Bairro: ${registro.bairro}`,
                    `CEP: ${registro.cep}`,
                    `Nome do Pai: ${registro.nomePai}`,
                    `Nome da Mãe: ${registro.nomeMae}`,
                    `Cônjuge: ${registro.conjugue}`,
                    `Profissão: ${registro.profissao}`,
                    `Celular: ${registro.celular}`,
                    `CPF: ${registro.cpf}`,
                    `RG: ${registro.rg}`,
                    `Órgão: ${registro.orgao}`,
                    `Data de Emissão: ${registro.dtemissao}`,
                    `Data de Nascimento: ${registro.nascimento}`,
                    `Naturalidade: ${registro.naturalidade}`,
                    `Estado: ${registro.estado}`,
                    `Qtd. Filhos: ${registro.qtd_filhos}`,
                    `Cargo: ${registro.cargo}`,
                    `Data de Batismo Águas: ${registro.dtbatismoAguas}`,
                    `Batismo ESP: ${registro.batismoES}`,
                    `Recebido Por: ${registro.recebidoPor}`,
                    `Data Recebido: ${registro.dataRecebido}`,
                    `Igreja Anterior: ${registro.igrejaAnterior}`,
                    `Cargo Anterior: ${registro.cargoAnterior}`,
                    `Tempo no Cargo: ${registro.tempoCargo}`
                ];

                // Adiciona as linhas de informações
                for (let j = 0; j < lines.length; j++) {
                    const line = lines[j];
                    doc.text(line, margin, yPosition);
                    yPosition += boxHeight + 2; // Move para baixo após cada linha
                }

                // Adiciona a linha acima do rodapé
                doc.line(margin, pageHeight - 15, pageWidth - margin, pageHeight - 15); // Linha horizontal acima do rodapé

                // Adiciona o rodapé na última página
                doc.text(`${footerText}${i + 1}`, margin, pageHeight - 10);
            }

            // Salva o PDF
            if (this.registros.length > 0)
                doc.save('relatorio.pdf');

        },
        async getStatus() {
            this.showForm = true;
            this.registros = [];
        },

    },
    mounted() {
        this.getStatus();
        this.starta();
    }

}
</script>

<style scoped>
.card {

    margin-left: 25vw;
    margin-top: 20vh;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    /* 5px rounded corners */
    width: 400px;
    height: 200px;

}

input {
    margin-left: 15px;
}


/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}

.card p {
    background-color: black;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
}

.menuButon {
    height: 40px;
    background-color: rgb(35, 29, 48);
    color: #fff;
    text-align: center;
    align-content: center;

}

#btnOk {
    width: 140px;
}

#btnOk:hover {
    background-color: #2196f3;
    color: #fff;
    transition: 0.3s;
    font-weight: bold;
}

#btnFechar {
    width: 140px;
}

#btnFechar:hover {
    background-color: #2196f3;
    color: #fff;
    transition: 0.3s;
    font-weight: bold;
}

#mostra {
    display: none;
}



.painel {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.secao {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    background-color: #f9f9f9;
}

.secao h2 {
    margin-top: 0;
    color: #007bff;
}

.secao p {
    margin: 5px 0;
}

.secao strong {
    color: #555;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>