<template>
    <!-- //#869e85e9; -->
    <div class="table-container">
        <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
            <h1> <strong style="color:black; ">Página de Congregações</strong></h1>
        </div>
        <div style="display: flex;">
            <input type="search" v-model="razao" class="input" placeholder="Digite a Razão Social">
            <button style="margin-left: 5px !important;" class="button is-info is-outlined" @click="buscaNome()">
                <img src="../assets/lupa.jpg" width="30" height="auto">
            </button>
        </div>


        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Razão Social</th>
                    <th>Endereço</th>
                    <th>Bairro</th>
                    <th>UF</th>
                    <th>CEP</th>
                    <th>Telefone</th>
                    <th>Opões</th>


                    <th>
                        <button class="button is-info is-outlined" @click="novaCongregacao()"><!-- @click="$router.push('/congregacao/nova' -->
                            <img src="../assets/icons8-add-properties-64.png" width="30" height="auto">
                            <!-- Delete -->
                        </button>
                        <!-- <a @click="$router.push('/congregacao/novo')" class="btn-novo">
                            <span><img src="../assets/icons8-add-file-96.png" width="30" height="auto"></span>
                        </a> -->
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="dados in dados" v-bind:key="dados.id">
                    <td>{{ dados.id }}</td>
                    <td>{{ dados.razaoSocial }}</td>
                    <td>{{ dados.endereco }}</td>
                    <td>{{ dados.bairro }}</td>
                    <td>{{ dados.estado }}</td>
                    <td>{{ dados.cep }}</td>
                    <td>{{ dados.telefone }}</td>
                    <td>
                        <button class="button is-danger is-outlined" @click="mostrar(dados.id, dados.razaoSocial)">
                            <img src="../assets/botao-de-deletar.png" width="30" height="auto">
                            <!-- Delete -->

                        </button>
                    </td>
                    <td>
                        <button class="button is-success is-outlined"
                            @click="$router.push('/congregacao/edit/' + dados.id)">
                            <img src="../assets/icons8-edit-account-64 (1).png" width="30" height="auto">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div :class="{ modal: true, 'is-active': showModal }">
            <div class="modal-content">
                <div class="msgModal">
                    <p><strong>Deseja deletar esse Registro!</strong></p>
                    <p>{{ this.nome }}</p>
                    <div class="munuBtn">
                        <button name="btnModal" id="btnModal" class="btn-novo" @click="fecharModal()">
                            Cancelar
                        </button>ㅤㅤ
                        <button name="btnModal" id="btnModal" class="btn-excluir" @click="excluir()">
                            Excluir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserInfo } from '@/lib/tools';
import axios from 'axios';
export default {
    data() {
        return {
            msg: null,
            name: 'congregacaoPage',
            id: "",
            showModal: false,
            razao: "",
            endereco: "",
            email: "",
            cep: "",
            bairro: "",
            telefone: "",
            dados: null,
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,
        }
    },
    methods: {
        async getIgrejas() {

            var userInfo = getUserInfo()
            console.log(userInfo);
            //this.congregacaoId = userInfo.congregacaoId;
            console.log('congregação ID .........:'+userInfo.congregacaoId);
            if (userInfo.congregacaoId == 1) {
                var req = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokenIgreja"),
                    },
                };
                axios.get(this.url + ":" + this.port + "/igrejas", req)   //req
                    .then((res) => {
                        this.dados = res.data;
                        console.log(res);
                    }).catch((error) => {
                        console.log(error);
                    });
            }


        },
        novaCongregacao(){
            var userInfo = getUserInfo()
            if (userInfo.congregacaoId == 1) {
                 this.$router.push({ path:'/congregacao/nova'});
            }
        },
        buscaNome() {
            var userInfo = getUserInfo()
            
            if (userInfo.congregacaoId == 1) {
                var razao = this.razao; 

                //console.log("================>" + razao);
                axios.get(this.url + ":" + this.port + "/igreja/" + razao, {})   //req
                    .then((res) => {
                        this.dados = res.data;
                        console.log(res);
                    }).catch((error) => {
                        console.log(error);
                    });
            }
        },
        mostrar(id, nome) {
            this.id = id;
            this.nome = nome;
            this.showModal = true;
            console.log("<<<<<<<    Id ------------>>>>>  " + this.id);
        },
        excluir() {
            this.showModal = true;
            this.deleteCongregacao(this.id);
        },
        fecharModal() {
            this.showModal = false;
        },
        async deleteCongregacao(id) {
            //var r = confirm("Deseja excluir esse Registro!");

            this.showModal = false;

            //var r = true;

            //if (r == true) {
            var req = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokenIgreja"),
                },
            };

            axios
                .delete(this.url + ":" + this.port + "/igreja/deletar/" + id, req)
                .then((res) => {
                    console.log(res);
                    //this.$router.push('/congregacao');
                    this.getIgrejas();
                })
                .catch((err) => {
                    this.msg = err.response.data.error;
                    console.log(this.msg);
                    setTimeout(() => (this.msg = ""), 3000);
                });
            //}
        }
    },
    mounted() {
        this.getIgrejas();
    }

}
</script>

<style scoped>
.munuBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.msgModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 2px;
    background-color: darkgray;

    height: 140px;
    width: 380px;
    border-radius: 20px;
    font-size: large;
    /*   align-content: center;
    align-items: center; */
}

#btnModal {
    width: 140px;
}

.table {
    width: 100%;
}

.table tr th {
    padding: 8px;
}

.btn-novo {
    color: black;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-excluir {
    color: #2196f3;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-novo:hover {
    background-color: #2196f3;
    color: #fff;
}

.btn-excluir:hover {
    background-color: #ca2b25;
    color: #fff;
}
</style>