<template>
    <div class="telalogin">
          
           <div>
              <div><MsgView :msg="msg" v-show="msg" /> </div>
                          
              <p>Login</p>
              <input type="text" name="nome" placeholder="edmilson.ludovico@gmail.com" class="input" v-model="nome">
              <p>Password</p>
              <input type="password" name="senha" placeholder="*******" class="input" v-model="senha">
              <hr>
              <button class="btn" @click="login">Logar</button>
          </div> 
          
    </div>
  </template>
  
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        senha: "",
        nome: "",
        msg: "",
        url: process.env.VUE_APP_API_URL,
        port: process.env.VUE_APP_PORT,
      };
    },
    components: {

    },
    methods: {
      login() {
        console.log(this.url + ":" + this.port + "/login");
  
   
        axios.post(this.url + ":" + this.port + "/login",{
            senha: this.senha,
            nome: this.nome,
          })
          .then((res) => {
  
            console.log(res.data);
            
            localStorage.setItem("tokenIgreja",  res.data.tokenIgreja); // salvando token
            localStorage.setItem("nivel", res.data.user.nivel);
            
            //localStorage.setItem("token", JSON.stringify(res.data.token));
            console.log(res.data.token);
            this.$router.push({ name: "home" });

          })
          .catch((err) => {
            this.msg = err.response.data.error;
          });
          
      },
    }
  };
  </script>
  
  <style scoped>
  .telalogin {
  
    display: flex;
    flex-direction: column;
    background-color: #9c9998;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10vh;
    max-width: 480px;
    max-height: 400px; 
    margin: auto ;
    text-align: center !important;
    align-items: center !important;
    gap: 5px;
    margin-top: 80px;
  
  }/* 
  .cabecalho{
    background-color: #0a0701;
    color:white
    text-align: center;
    border: 3px solid green;
  }
   */
   .telalogin p {
    color: #666;
    font-weight: bold;
  } 
  .telalogin input {
    padding: 15px;
    font-size: 14px;
    width: 250px ;
    border: 1px solid #ccc;
    margin-bottom: 20px; 
    margin-top: 5px; 
    border-radius: 4px;
    transition: all linear 160ms; 
    outline: none; 
    border-radius: 10px;
    text-align: center;
    
  }
  
  
  
  .formLogin {
    display: flex;
    flex-direction: column;
    background-color: #9c9998;
    border-radius: 10px;
    padding: 40px;
    margin-top: 20vh;
    margin: auto !important;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.4);
    gap: 5px;
    text-align: center !important;
    align-items: center !important;
    max-width: 25% !important;
  }
  /*
  formLogin {
    height: 400px;
    width: 300px;
  } */
  
  .formLogin h1 {
    color: #104e88;
    padding: 0;
    margin: 0;
    font-weight: 500 !important;
    font-size: 2.3em !important;
  }
  
  .formLogin p {
    display: inline-block;
    font-size: 14px;
    color: #666;
    margin-bottom: 25px;
  
  }
  
  .formLogin input {
    padding: 15px;
    font-size: 14px;
    width: 250px !important;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    margin-top: 5px;
    border-radius: 4px;
    transition: all linear 160ms;
    outline: none;
    border-radius: 10px;
    text-align: center;
    
  }
  
  .telalogin input:focus {
    border: 1px solid #0a0701;
  }
  
  .formLogin label {
    font-size: 14px;
    font-weight: 300;
  }
  
  .telalogin a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 13px;
    color: #555;
    transition: all linear 160ms;
  }
  
  .telalogin a:hover {
    color: #4e5bce;
    font-weight: bolder;
  }
  
  .btn {
    background-color: #104e88;
    color: #fff;
    font-size: 14px;
     font-weight: 300; 
     width: 13vw !important;
    height: 40px; 
    border: none !important;
    transition: all linear 160ms;
    cursor: pointer;
    margin: 0 !important; 
    border-radius: 10px;
    font-weight: bolder;
    font-family: 'Arial';
  }
  
  .btn:hover {
    transform: scale(1.05);
    background-color: #63b6dd;
    color: black;
    font-weight: bolder;
    font-family: 'Arial';
  }
  </style>
  