<script setup>
    import formUsuario from '../../components/formUsuario.vue';
    import { fetcher } from '../../lib/fetcher'
    import { onMounted, ref } from 'vue';
    import router from '@/router'

    let usuario = null;
    let loading = ref(true);
    const idUsuario = router.currentRoute.value.params.id
    fetcher.get(`/usuarios/${idUsuario}`).then(res => {
        console.log('fetch user',res.data)
        usuario = res.data;
        loading.value = false;
    })

</script>
<template>
    <div>
        <formUsuario
            v-if="loading == false"
            formType="update"
            :usuario="usuario"
        />

        <div
            v-if="loading"
            style="padding: 40px; font-size: 24px;">
            Carregando...
        </div>
    </div>
</template>