<template>    
    <div>
        <div>
            <membroPage />
        </div>     
    </div>
</template>
<script>
  import membroPage from '../../components/membroPage.vue'
  /* import membroPageDadosGerais from '../../components/membroDadosGerais.vue' */
  export default {

    components: {
        membroPage,
       /*  membroPageDadosGerais */
    },
    methods: {

    }
}
</script>

<style scoped>


</style>