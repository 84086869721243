<template>
    <div>
      <p class="panel-heading">
        Cadastro de Membros- Módulo Alteração
      </p>
      <span style="position:absolute; top: 40%; left: 35%;
       z-index:1000; background-color:#333; color:#fff; width: 20%;
       height:auto;text-align:center; border-radius:10px; "> {{msg}} </span>
      <div class="tabs is-toggle is-fullwidth">
        <ul style="width: 30px !important;">
          <li @click="activeTab = '1'" :class="[activeTab === '1' ? 'is-active' : 'icon is-small']">
            <a>
              <span>Gerais</span>
            </a>
          </li>
          <li @click="activeTab = '2'" :class="[activeTab === '2' ? 'is-active' : 'icon is-small']">
            <a>
              <span>Pessoais</span>
            </a>
          </li>
          <li @click="activeTab = '3'" :class="[activeTab === '3' ? 'is-active' : 'icon is-small']">
            <a>
              <span>Documentos</span>
            </a>
          </li>
          <li @click="activeTab = '4'" :class="[activeTab === '4' ? 'is-active' : 'icon is-small']">
            <a>
              <span>Igreja</span>
            </a>
          </li>
        </ul>
      </div>
  
      <div>
  
        <div v-if="activeTab === '1'">
          <article class="panel is-link">
            <div class="principal">
  
              <div class="field">
                <label class="label">Nome:</label>
                <input v-model="nome" class="input" type="text" id="nome" style="width: 400px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Cargo:</label>
                <input v-model="cargo" class="input" type="text" id="cargo" style="width: 400px;" />&nbsp &nbsp
              </div>
            </div>
            <div class="principal">
              <div class="field">
                <label class="label">Pai:</label>
                <input v-model="nomePai" class="input" type="text" id="nomePai" style="width: 400px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Mae:</label>
                <input v-model="nomeMae" class="input" type="text" id="nomeMae" style="width: 400px;" />&nbsp &nbsp
              </div>
            </div>
            <div class="principal">

              <div class="field">
                <label class="label">Estado Civil:</label>
                <div  class="select is-link">
                  <select v-model="estadoCivil">
                    <option v-for="opEstadoCivil in opEstadoCivil" v-bind:value="opEstadoCivil.value">
                      {{ opEstadoCivil.text }}
                  </option>
                  </select>
                </div>
              </div>&nbsp &nbsp

              <div class="field">
                <label class="label">Conjugue:</label>
                <input v-model="conjugue" class="input" type="text" id="conjugue" style="width: 400px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Nr.Filhos:</label>
                <input v-model="qtdFilhos" class="input" type="text" id="qtdFilhos" style="width: 50px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Naturalidade:</label>
                <input v-model="naturalidade" class="input" type="text" id="naturalidade" style="width: 335px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Departamento:</label>
                <div  class="select is-link">
                  <select v-model="dptoId" class="select">
                    <option v-for="op in op" v-bind:value="op.value">
                        {{ op.text }}
                    </option>
                  </select>
   
                </div> 
              </div>
            </div>
            <br>
            <br>
          </article>
        </div>
  
        <div v-if="activeTab === '2'">
          <article class="panel is-link">
            <div class="principal">
              <div class="field">
                <label class="label">Endereço:</label>
                <input v-model="endereco" class="input" type="text" id="endereco" style="width: 400px;" />&nbsp &nbsp
              </div>

              <div class="field">
                <label class="label">Bairro:</label>
                <input v-model="bairro" class="input" type="text" id="bairro" style="width: 350px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">CEP:</label>
                <input v-model="cep" class="input" type="text" id="cep" style="width: 150px;" />&nbsp &nbsp
              </div>  

            </div>  
            <div class="principal">

              <div class="field">
                <div class="field">
                  <label class="label">Cidade:</label>
                  <input v-model="cidade" class="input" type="text" id="cidade" style="width: 400px;" />&nbsp &nbsp
                </div>
              </div>
              <div class="field">
                <label class="label">Estado:</label>
                <div  class="select is-link">
                  <select v-model="estado" class="select">
                    <option v-for="option in options" v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                  </select>
  
                </div>
              </div>
            </div>
            <div class="principal">
                <div class="field">
                  <label class="label">E-MAIL:</label>
                  <input v-model="email" class="input" type="email" id="email" style="width: 400px;" />&nbsp &nbsp  
                </div>
                <div class="field">
                  <label class="label">Profissão:</label>
                  <input v-model="profissao" class="input" type="email" id="profissao" style="width: 348px;" />&nbsp &nbsp  
                </div>
                <div class="field">
                  <label class="label">Celular:</label>
                  <input v-model="celular" class="input" type="text" id="celular" style="width: 150px;" />&nbsp &nbsp  
                </div>
            </div>
  
            <br><br>
          </article>
        </div>
  
  
        <div v-if="activeTab === '3'">
          <article class="panel is-link">
            <div class="principal">
              <div class="field">
                <label class="label">RG:</label>
                <input v-model="rg" class="input" type="text" id="rg" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Orgão Emissor:</label>
                <input v-model="orgao" class="input" type="text" id="orgao" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Dt.Emissão:</label>
                <input v-model="emissao" class="input" type="date" id="emissao" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">CPF:</label>
                <input v-model="cpf" class="input" type="text" id="cpf" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Dt.Nascimento:</label>
                <input v-model="nascimento" class="input" type="date" id="nascimento" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Batismo Águas:</label>
                <input v-model="btAguas" class="input" type="date" id="btAguas" style="width: 150px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Batismo ES:</label>
                <input v-model="btES" class="input" type="date" id="btES" style="width: 150px;" />&nbsp &nbsp
              </div>  
              
            </div>
            <br><br>
          </article>
        </div>
  
        <div v-if="activeTab === '4'">
          <article class="panel is-link">
            <div class="principal">
              <div class="field">
                <label class="label">Igreja Anterior:</label>
                <input v-model="igrejaAnterior" class="input" type="text" id="igrejaAnterior" style="width: 400px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Recebido Por:</label>
                <input v-model="recebidoPor" class="input" type="text" id="recebidoPor" style="width: 400px;" />&nbsp &nbsp
              </div>
            </div>
            <div class="principal">
              <div class="field">
                <label class="label">Cargo Anterior:</label>
                <input v-model="cargoAnterior" class="input" type="text" id="cargoAnterior" style="width: 400px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Tempo no Cargo:</label>
                <input v-model="tempoCargo" class="input" type="text" id="tempoCargo" style="width: 200px;" />&nbsp &nbsp
              </div>
              <div class="field">
                <label class="label">Data Recebido:</label>
                <input v-model="dataRecebido" class="input" type="date" id="dataRecebido" style="width: 180px;" />&nbsp &nbsp
              </div>

            </div>
            
            <div class="principal">

              <div class="control">
                <label class="radio">
                <input type="radio" value= "Ativo" v-model="situacao">
                   Ativo
                </label>
                <label class="radio">
                  <input type="radio" value="Afastado" v-model="situacao">
                    Afastado
                </label>


                <div class="field" v-if="situacao === 'Afastado'">     
                  <label class="label">Data Desligamento:</label>
                  <input v-model="dataDesligamento" class="input" type="date" id="dataDesligamento" style="width: 180px;" />&nbsp &nbsp
                </div>
              </div>

              <div class="field">
                  <label class="label">Observações:</label>
                  <textarea v-model="observacoes"  :class="[situacao === 'Afastado' ? 'txtArea' : 'txtArea2']" rows="8"></textarea>
              </div>
            </div>
            <br>
          </article>

        </div>
          


      </div>


      <div class="btn">
        <br>
        <button class="button is-info is-outlined" @click="gravar()">A L T E R A R</button><br><br>
      </div> 
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as moment from 'moment'

  console.log('Data atual Momentjs - ', moment().format('DD/MM/YYYY'))

  export default {
    data() {
      return {
        activeTab: '1',
        msg: "",
        id: '',
        nome: '',
        cargo: '',
        nomePai: '',
        nomeMae: '',
        conjugue: '',
        naturalidade: '',
  
        endereco: '',
        cidade: '',
        bairro: '',
        cep: '',
        dptoId: '',
        op: [
            { text: 'Crianças', value: '1' },
            { text: 'Jovens', value: '2' },
            { text: 'Adolescentes', value: '3' },
            { text: 'Irmãs', value: '4' },
            { text: 'Varões', value: '5' },
            { text: 'Banda', value: '6' },
            { text: 'Congregados', value: '7' },
            { text: 'Ministério', value: '8' },
            { text: 'Nenhum', value: '9' }
  
           ],
        estadoCivil: '',
        opEstadoCivil: [
            { text: 'Casado(a)', value: '1' },
            { text: 'Solteiro(a)', value: '2' },
            { text: 'Viúvo(a)', value: '3' },
            { text: 'Divorciado(a)', value: '4' }
           ],
        estado: 'RJ',
          options: [
            { text: 'Acre', value: 'AC' },
                      { text: 'Alagoas', value: 'AL' },
                      { text: 'Amapa', value: 'AP' },
                      { text: 'AM', value: 'AM' },
                      { text: 'Bahia', value: 'BA' },
                      { text: 'Ceará', value: 'CE' },
                      { text: 'Distrito Federal', value: 'DF' },
                      { text: 'Espirito Santo', value: 'ES' },
                      { text: 'Goiás', value: 'GO' },
                      { text: 'Maranhão', value: 'MA' },
                      { text: 'Mato Grosso', value: 'MT' },
                      { text: 'Mato Grosso do Sul', value: 'MS' },
                      { text: 'Minas Grrais', value: 'MG' },
                      { text: 'Pará', value: 'PA' },
                      { text: 'Paraíba ', value: 'PB' },
                      { text: 'Paraná', value: 'PR' },
                      { text: 'Pernambuco', value: 'PE' },
                      { text: 'Piauí', value: 'PI' },
                      { text: 'Rio de Janeiro', value: 'RJ' },
                      { text: 'Rio do Grande do Norte', value: 'RN' },
                      { text: 'Rio do Grande do Sul', value: 'RS' },
                      { text: 'Rôndonia', value: 'RO' },
                      { text: 'Roraima', value: 'RR' },
                      { text: 'Santa Catarina', value: 'SC' },
                      { text: 'São Paulo', value: 'SP' },
                      { text: 'Sergipe', value: 'SE' },
                      { text: 'Tocantins', value: 'TO' }
                   ],
        email:'',
        profissao: '',
        celular: '',
        cpf: '',
        rg: '',
        orgao: '',
        qtdFilhos: '',
        nascimento: null,
        btES: null,
        btAguas: null,
        igrejaAnterior: '',
        recebidoPor: null,
        dataRecebido: null,
        emissao: null,

        observacoes: '',
        dataDesligamento: null,
        situacao: '',

        cargoAnterior: null,
        tempoCargo: '',
        congregacaoId: '',

        url: process.env.VUE_APP_API_URL,
        port: process.env.VUE_APP_PORT,
        dados: null
  
      }
    },
    methods: {
      exibeDados() {
        this.id = this.$route.params.id;
        axios.get(this.url+":"+this.port+"/membro/membroid/"+this.id,{})
        .then((res) => {
            const dados = res.data[0];
            console.log(res.data.nome);

            this.nome = dados.nome;
            this.cargo =  dados.cargo;
            this.nomePai =  dados.nomePai;
            this.nomeMae =  dados.nomeMae;
            this.estadoCivil = dados.estadoCivil
            this.conjugue = dados.conjugue;
            this.naturalidade = dados.naturalidade;
            this.qtdFilhos = dados.qtdFilhos;
            this.endereco = dados.endereco;
            this.cidade = dados.cidade;
            this.bairro = dados.bairro;
            this.cep = dados.cep;
            this.dptoId = dados.dptoId;
            this.estado = dados.estado;
            this.email = dados.email;
            this.profissao = dados.profissao;
            this.celular = dados.celular;
            this.cpf = dados.cpf;
            this.rg = dados.rg;
            this.orgao = dados.orgao;
            this.igrejaAnterior = dados.igrejaAnterior;
            this.recebidoPor = dados.recebidoPor;
            this.cargoAnterior = dados.cargoAnterior;
            this.tempoCargo = dados.tempoCargo;

            this.nascimento = dados.nascimento;

            this.btES = dados.batismoES;
            this.btAguas = dados.batismoAguas;

            this.dataRecebido = dados.dataRecebido;
            this.emissao = dados.emissao;

            this.observacoes = dados.observacoes;
            this.dataDesligamento = dados.dataDesligamento;
            this.situacao = dados.situacao;
            this.congregacaoId = dados.congregacaoId;

            if(this.nascimento != null){
              this.nascimento = moment(dados.nascimento).format('YYYY-MM-DD');
            }else{
              console.log('Exibe nascimento '+this.nascimento);
            }          
            if(this.btES != null){
              this.btES = moment(dados.batismoES).format('YYYY-MM-DD');
            }
            if(this.btAguas != null){
              this.btAguas = moment(dados.batismoAguas).format('YYYY-MM-DD');
            }
            if(this.dataRecebido != null){
              this.dataRecebido = moment(dados.dataRecebido).format('YYYY-MM-DD');
            }
            if(dados.emissao != null){
              this.emissao = moment(dados.emissao).format('YYYY-MM-DD');
            }
            if(dados.dataDesligamento != null){
              this.dataDesligamento = moment(dados.dataDesligamento).format('YYYY-MM-DD');
            }
            
            
            //this.$router.push('/membro');

          })
          .catch((err) => {
            this.msg = err.response.data.error;
            console.log(this.msg);
            setTimeout(() => (this.msg = ""), 3000);
          });
      },

      gravar() {
             
        if (this.emissao ==''){
          this.emissao = null;
        }
        if (this.dataRecebido ==''){
          this.dataRecebido = null;
        }
        if (this.btAguas ==''){
          this.btAguas = null;
        }
        if (this.btES ==''){
          this.btES = null;
        }
        if (this.nascimento == ''){
          this.nascimento = null;
        } 
       if (this.dataDesligamento == ''){
         this.dataDesligamento = null;
       } 

        axios.post(this.url+":"+this.port+"/membro/alterar",{
          id: this.id,
          nome: this.nome,
          endereco: this.endereco,
          cidade: this.cidade,
          email: this.email,
          cep: this.cep,
          bairro: this.bairro,
          celular: this.celular,
          estado: this.estado,
          cargo: this.cargo,
          nomePai: this.nomePai,
          nomeMae: this.nomeMae,
          estadoCivil: this.estadoCivil,

          conjugue: this.conjugue,
          naturalidade: this.naturalidade,
          qtdFilhos: this.qtdFilhos,
          dptoId: this.dptoId,
          profissao: this.profissao,
          cpf: this.cpf,
          rg: this.rg,

          nascimento: this.nascimento,
          batismoES: this.btES,
          batismoAguas: this.btAguas,
          emissao: this.emissao,
          orgao: this.orgao,

          dataRecebido: this.dataRecebido,


          igrejaAnterior: this.igrejaAnterior,
          recebidoPor:this.recebidoPor,
          cargoAnterior: this.cargoAnterior,
          tempoCargo: this.tempoCargo,
          congregacaoId: this.congregacaoId,

          observacoes: this.observacoes,
          situacao: this.situacao,
          dataDesligamento: this.dataDesligamento 

          })
          .then((res) => {
            this.msg = "Membro alterado com sucesso";
            setTimeout(() => (this.msg = ""), 3000);
  
  
            this.$router.push('/membro');
            console.log(res);
          })
          .catch((err) => {
            this.msg = err.response.data.error;
            console.log(this.msg);
            setTimeout(() => (this.msg = ""), 3000);
          });
       
      }
  
    },
    mounted() {
      this.exibeDados();
      console.log("olá")
    }
  }
  </script>
  <style scoped>
  .lbl {
    font-size: 10px;
    font-weight: bold;
    margin-left: 2%;
  }
  .txtArea {
    width: 615px;
  }
  .txtArea2 {
    width: 695px;
  }
  
  .principal {
    display: flex;
    flex-direction: row;
    margin-left: 2%;
  }
  
  .principal label {
    font-size: 12px;
    font-weight: bold;
    margin-left: 2%;
  }
  
  .btn {
    margin-left: 2%;
  }
  </style>
  
  
  