<template>
  <footer id="footer">
    <div id="flex-item-right">
      <p>EMDL Soluções em Informatica &copy; 2023</p>
    </div>
    <div id="flex-item-left">
      <p>Sistema de Gestão de Igrejas</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "my-footer",
};
</script>

<style scoped>
#footer {
  height: 35px;
  background-color: black;
  border-top: 4px solid black;
  color: white;
  display: flex;
  flex-direction: columns;
  align-items: center;
  padding: 20px;
  width: 100%;
  bottom: 0;
  position: fixed;
}

#flex-item-left {
  flex: 50%;
  text-align: right;
}

#flex-item-right {
  flex: 50%;
}
</style>