<template>    
    <div>
        <congregacaoPage />  
    </div>
</template>
<script>
  import congregacaoPage from '../../components/congregacaoPage.vue'
  export default {
    props: { menu: Number },
    components: {
      congregacaoPage
    },
    created(){
      console.log('Menu passado..: '+this.menu)
    },
    methods: {
      

    }
}
</script>

<style scoped>


</style>